import React from 'react';
import  { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { TextField } from '@walmart-web/livingdesign-components';
import Loader from '../../commonComponents/appComponents/loader/loader';
import DataTable from 'react-data-table-component';
import './eventView.css';
import {
  ChevronDown,
  ChevronUp,
  Search,
} from '../../commonComponents/icons/icon';
import FormControl from '@material-ui/core/FormControl';
import { Popover } from '@walmart-web/livingdesign-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LDDivider from '../../commonComponents/uiComponents/LDDivider';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import moment from 'moment';
import Reservation from '../reservations/reservation';
import { Checkbox, Tooltip } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import UserContext from '../../store/user-context';
import { contextPath } from 'src/constants';
import { useMutation } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { ChevronRight } from '@material-ui/icons';
const format = 'MMM DD, YYYY';
const service: Service = SpaceDonationService;
const EventView: React.FunctionComponent = () => {
  return <DisplayEventRequest />;
};
export default EventView;
const DisplayEventRequest: React.FunctionComponent = () => {
  const [view, setview] = useState('listview');
  return (
    <>
      <div className='nc-npo-event-header'>Events</div>
      <div className='views-list-agenda'>
        <div
          data-testid='listview'
          className={`nc-npo-listbtn ${
            view === 'listview' ? 'selectedview' : 'notselectedview'
          }`}
          onClick={() => {
            setview('listview');
          }}
        >
          <div>List view</div>
        </div>
        <div
          data-testid='agendaview'
          className={`nc-npo-agendabtn ${
            view === 'agendaview' ? 'selectedview' : 'notselectedview'
          }`}
          onClick={() => {
            setview('agendaview');
          }}
        >
          <div>Agenda view</div>
        </div>
      </div>
      <LDDivider className='nc-npo-event-request-divider' />
      {view === 'listview' && <ListView />}
      {view === 'agendaview' && <Reservation />}
    </>
  );
};
export const ListView: React.FunctionComponent = () => {
  const userContext: any = useContext(UserContext);
  const myOrgId = userContext?.organization?.id;
  const isVerified = userContext?.organization?.isVerified;
  const defaultPayload = {
    orgId: myOrgId,
    pageSize: 10,
    pageNum: 1,
    eventTypes: [] as any,
    statuses: [] as any,
    searchKey: '',
    orderDir: 'DESC',
  };
  // @ts-ignore
  const handleSort = async (column: any, sortDirection: any) => {
    setPayload((prev) => {
      const updated = { ...prev };
      updated.orderDir = sortDirection.toUpperCase();
      return updated;
    });
  };
  const [value, setValue] = useState('');
  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);
  const [ncFilter, setNcFilter] = useState(false);
  const [lcFilter, setLcFilter] = useState(false);
  const [pending, setPending] = useState(false);
  const [upcoming, setUpcoming] = useState(false);
  const [ongoing, setOngoing] = useState(false);
  const [past, setPast] = useState(false);
  const ref = React.createRef<HTMLElement>();
  const ref1 = React.createRef<HTMLElement>();
  const [payload, setPayload] = useState(defaultPayload);
  const history = useNavigate();
  const handleHomeOffice = (ncDataSource: any) => {

    history(`${contextPath}/space-donation/events/ngo-detail` ,
     { state: {
        orgId: myOrgId,
        ncId: ncDataSource.id,
      }
    });
  };
  const handleLocalCampaign = (ncDataSource: any) => {
    mutationCampaign.mutate(ncDataSource.id);
  };
  const filtersCount = useMutation((data: any) =>
    service.getFiltersCount(data)
  );

  useEffect(() => {
    filtersCount.mutate(myOrgId);
  }, [myOrgId]);
  const columns = [
    {
      name: 'Event Name',
      selector: (row: any) => row.eventName,
      cell: (row: any) =><Tooltip title={row?.eventName} placement='right'>
            <span className='ngo-nc-address-span-tooltip'>{row?.eventName}</span></Tooltip>,
    },
    {
      name: 'Start Date',
      selector: (row: any) => moment(row.eventStartDateString).format(format),
      sortable: true,
    },
    {
      name: 'End date',
      selector: (row: any) => moment(row.eventEndDateString).format(format),
    },
    {
      name: 'Requested',
      selector: (row: any) => row.requested,
    },
    {
      name: 'Approved',
      selector: (row: any) => (row.approved ? row.approved : '-'),
    },
    {
      name: 'Denied',
      selector: (row: any) => (row.optedOut ? row.optedOut : '-'),
    },
    {
      name: 'Pending',
      selector: (row: any) => row.pending,
    },
    {
      name: 'Action',
      cell: (row: any) => (
        <ChevronRight
          data-testid='actionBtn'
          className='nc-npo-actionbtn-icon'
          onClick={
            row?.donationType === 'SPACE_DONATION_NC'
              ? () => handleHomeOffice(row)
              : () => handleLocalCampaign(row)
          }
        >
          Action
        </ChevronRight>
      ),
    },
  ];
  const mutationCampaign = useMutation((data: any) =>
    service.getLocalCampaignData(data)
  );
  useEffect(() => {
    if (mutationCampaign?.isSuccess) {
      history( `${contextPath}/space-donation/events/detail`,
       { state: {
          spaceDonation: mutationCampaign?.data.spaceDonation,
          waaSTaskMessage: mutationCampaign?.data.waaSTaskMessage,
        },
      });
    }
  }, [mutationCampaign]);
  const mutation = useMutation((data: any) =>
    service.getEventDashboardData(data)
  );
  useEffect(() => {
    mutation.mutate(payload);
  }, []);
  useEffect(() => {
    getAPIdata();
  }, [payload.searchKey, payload.orderDir, payload.pageNum]);
  const handlePageChange = (page: any) => {
    setPayload((prev) => {
      const updated = { ...prev };
      updated.pageNum = page;
      return updated;
    });
  };
  const handleDebounceFn = (inputValue: any) => {
    setPayload((prev) => {
      const updated = { ...prev };
      updated.searchKey = inputValue;
      return updated;
    });
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const getData = (e: any) => {
    setValue(e.target.value);
    debounceFn(e.target.value);
  };
  const getAPIdata = () => {
    mutation.mutate(payload);
  };
  const openEventPopover = () => {
    setState(!state);
  };
  const handleResetEventType = () => {
    payload.eventTypes = [];
    setNcFilter(false);
    setLcFilter(false);
  };
  const handleApplyEventType = () => {
    getAPIdata();
    setState(false);
  };
  const openStatusPopover = () => {
    setState1(!state1);
  };
  const statusResetType = () => {
    payload.statuses = [];
    setPending(false);
    setUpcoming(false);
    setOngoing(false);
    setPast(false);
  };
  const statusApplyType = () => {
    setState1(false);
    getAPIdata();
  };
  const handleClean = () => {
    payload.statuses = [];
    payload.eventTypes = [];
    payload.searchKey = '';
    setPending(false);
    setUpcoming(false);
    setOngoing(false);
    setPast(false);
    setNcFilter(false);
    setLcFilter(false);
    getAPIdata();
    setValue('');
  };
  const eventFilterHandler = (event: any) => {
    setPayload((prev) => {
      const updated = { ...prev };
      updated.eventTypes = updated.eventTypes || [];
      if (event.target.checked) {
        updated.eventTypes.push(event.target.value);
      } else {
        updated.eventTypes = updated.eventTypes.filter(
          (item: any) => item !== event.target.value
        );
      }
      return updated;
    });
  };
  const statusFilterHandler = (event: any) => {
    setPayload((prev) => {
      const updated = { ...prev };
      updated.statuses = updated.statuses || [];
      if (event.target.checked) {
        updated.statuses.push(event.target.value);
      } else {
        updated.statuses = updated.statuses.filter((item: any) => {
          return item !== event.target.value;
        });
      }
      return updated;
    });
  };
  return (
    <div className='events-view-maincotainer'>
      <div className='nc-npo-event-request-container'>
        <div className='search-filter'>
          <div className='nc-npo-popover-group'>
            <Popover
              data-testid='popover'
              className='nc-npo-popover-styling '
              content={
                <div
                  data-testid='event-div'
                  className='nc-npo-event-type-radio-select'
                >
                  <FormControl data-testid='form-control'>
                    <FormLabel id='demo-controlled-radio-buttons-group'>
                      <div className='nc-npo-filter-header'>Event Type</div>
                    </FormLabel>
                    <RadioGroup
                      data-testid='radio-group'
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                    >
                      {isVerified && <FormControlLabel
                        value='Home Office'
                        data-testid='form-control-1'
                        control={
                          <Checkbox
                            data-testid='nc-npo-event-radio-button-1'
                            color='default'
                            size='medium'
                            className='nc-npo-event-radio-button'
                            checked={ncFilter}
                          />
                        }
                        label={
                          <div
                            className={`${
                              !ncFilter
                                ? 'not-selected-filter'
                                : 'selected-filter'
                            }`}
                          >
                           <div>Campaign</div>
                            <div>
                              (
                              {filtersCount.isLoading
                                ? '...'
                                : filtersCount?.data?.eventType?.homeOffice}
                              )
                            </div>
                          </div>
                        }
                        onChange={(event: any) => {
                          eventFilterHandler(event);
                          setNcFilter(!ncFilter);
                        }}
                      />}
                      <FormControlLabel
                        value='Local Campaign'
                        data-testid='form-control-2'
                        control={
                          <Checkbox
                            data-testid='nc-npo-event-radio-button-2'
                            color='default'
                            size='medium'
                            className='nc-npo-event-radio-button'
                            checked={lcFilter}
                          />
                        }
                        label={
                          <div
                            className={`${
                              !lcFilter
                                ? 'not-selected-filter'
                                : 'selected-filter'
                            }`}
                          >
                            <div>Local Campaign</div>
                            <div>
                              (
                              {filtersCount.isLoading
                                ? '...'
                                : filtersCount?.data?.eventType?.localCampaign}
                              )
                            </div>
                          </div>
                        }
                        onChange={(event: any) => {
                          eventFilterHandler(event);
                          setLcFilter(!lcFilter);
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                  <LDDivider className='nc-npo-event-request-divider' />
                  <div className='nc-npo-event-type-apply-reset-button'>
                    <LDButton
                      variant='tertiary'
                      data-testid='reset-btn'
                      onClick={() => handleResetEventType()}
                    >
                      Reset
                    </LDButton>
                    <LDButton
                      variant='primary'
                      data-testid='apply-btn'
                      onClick={() => handleApplyEventType()}
                    >
                      Apply
                    </LDButton>
                  </div>
                </div>
              }
              isOpen={state}
              onClose={() => setState(false)}
              position='right'
              triggerRef={ref}
            >
              <LDButton
                data-testid='openPopover'
                onClick={() => openEventPopover()}
              >
                Event Type{' '}
                {!state ? (
                  <ChevronDown size='small' />
                ) : (
                  <ChevronUp size='small' />
                )}
              </LDButton>
            </Popover>
            <Popover
              data-testid='status-popover'
              className='nc-npo-popover-styling  status-filters'
              content={
                <div className='nc-npo-event-type-radio-select'>
                  <FormControl>
                    <FormLabel id='demo-controlled-radio-buttons-group'>
                      <div className='nc-npo-filter-header'>Status</div>
                    </FormLabel>
                    <RadioGroup
                      data-testid='radio-group'
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                    >
                      <FormControlLabel
                        data-testid='pending-form'
                        value='Pending'
                        control={
                          <Checkbox
                            color='default'
                            size='medium'
                            className='nc-npo-event-radio-button'
                            checked={pending}
                          />
                        }
                        label={
                          <div
                            className={`${
                              !pending
                                ? 'not-selected-filter'
                                : 'selected-filter'
                            }`}
                          >
                            <div>Pending</div>
                            <div>
                              (
                              {filtersCount.isLoading
                                ? '...'
                                : filtersCount?.data?.status?.pending}
                              )
                            </div>
                          </div>
                        }
                        onChange={(event: any) => {
                          statusFilterHandler(event);
                          setPending(!pending);
                        }}
                      />
                      <FormControlLabel
                        value='Upcoming'
                        data-testid='upcoming-form'
                        control={
                          <Checkbox
                            color='default'
                            size='medium'
                            className='nc-npo-event-radio-button'
                            checked={upcoming}
                          />
                        }
                        label={
                          <div
                            className={`${
                              !upcoming
                                ? 'not-selected-filter'
                                : 'selected-filter'
                            }`}
                          >
                            <div>Upcoming</div>
                            <div>
                              (
                              {filtersCount.isLoading
                                ? '...'
                                : filtersCount?.data?.status?.upcoming}
                              )
                            </div>
                          </div>
                        }
                        onChange={(event: any) => {
                          statusFilterHandler(event);
                          setUpcoming(!upcoming);
                        }}
                      />
                      <FormControlLabel
                        value='Ongoing'
                        control={
                          <Checkbox
                            color='default'
                            size='medium'
                            className='nc-npo-event-radio-button'
                            checked={ongoing}
                          />
                        }
                        label={
                          <div
                            className={`${
                              !ongoing
                                ? 'not-selected-filter'
                                : 'selected-filter'
                            }`}
                          >
                            <div>Ongoing</div>
                            <div>
                              (
                              {filtersCount.isLoading
                                ? '...'
                                : filtersCount?.data?.status?.ongoing}
                              )
                            </div>
                          </div>
                        }
                        data-testid='ongoing-form'
                        onChange={(event: any) => {
                          statusFilterHandler(event);
                          setOngoing(!ongoing);
                        }}
                      />
                      <FormControlLabel
                        value='Past'
                        control={
                          <Checkbox
                            color='default'
                            size='medium'
                            className='nc-npo-event-radio-button'
                            checked={past}
                          />
                        }
                        label={
                          <div
                            className={`${
                              !past ? 'not-selected-filter' : 'selected-filter'
                            }`}
                          >
                            <div>Past</div>
                            <div>
                              (
                              {filtersCount.isLoading
                                ? '...'
                                : filtersCount?.data?.status?.past}
                              )
                            </div>
                          </div>
                        }
                        data-testid='past-form'
                        onChange={(event: any) => {
                          statusFilterHandler(event);
                          setPast(!past);
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                  <LDDivider className='nc-npo-event-request-divider' />
                  <div className='nc-npo-event-type-apply-reset-button'>
                    <LDButton
                      data-testid='status-reset'
                      variant='tertiary'
                      onClick={() => statusResetType()}
                    >
                      Reset
                    </LDButton>
                    <LDButton
                      data-testid='status-apply'
                      variant='primary'
                      onClick={() => statusApplyType()}
                    >
                      Apply
                    </LDButton>
                  </div>
                </div>
              }
              isOpen={state1}
              onClose={() => setState1(false)}
              position='right'
              triggerRef={ref1}
            >
              <LDButton
                data-testid='open-popoverbutton'
                className='select-event-type-button'
                onClick={() => openStatusPopover()}
              >
                Status{' '}
                {!state1 ? (
                  <ChevronDown size='small' />
                ) : (
                  <ChevronUp size='small' />
                )}
              </LDButton>
            </Popover>
            <div className='nc-npo-btn-container'>
              <div
                data-testid='clean-btn'
                className='nc-npo-cleanBtn'
                onClick={handleClean}
              >
                Clear all
              </div>
            </div>
          </div>
          <div>
            <TextField
              className='nc-npo-txtfield'
              label=''
              leadingIcon={<Search size='small' />}
              onChange={getData}
              value={value}
              placeholder={'Search event name here'}
              data-testid='search-input'
              size='small'
            />
          </div>
        </div>
      </div>
      <div className='nc-npo-event-container'>
        {mutation.isLoading && <Loader />}
        <DataTable
          data-testid='data-table'
          className='nc-npo-event-request-items'
          columns={columns}
          data={mutation?.data?.content}
          onSort={handleSort}
          sortServer={true}
          pagination={true}
          onChangePage={handlePageChange}
          paginationServer={true}
          paginationPerPage={10}
          paginationTotalRows={mutation?.data?.totalElements}
          paginationRowsPerPageOptions={[10]}
        />
      </div>
    </div>
  );
};
