import React, { useContext, useState, useEffect } from 'react';
import { Note, Store, Phone, Calendar, InfoCircle } from '../../commonComponents/icons/icon';
import Card from '../../commonComponents/uiComponents/LDCard';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';
import Divider from '../../commonComponents/uiComponents/LDDivider';
import Button from '../../commonComponents/uiComponents/LDButton';
import { contextPath } from 'src/constants';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import { useMutation , useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import moment from 'moment-timezone';
import { getLocalTimeFromStoreTimeZone, DEFAULT_TIME_ZONE } from 'src/components/spacedonation/scenes/common/timeZoneHelperUtil';
import UserContext from '../../store/user-context';
import LDCheckbox from '../../commonComponents/uiComponents/LDCheckBox';
import { CloseCircleFill } from '../../commonComponents/icons/icon';
import Modal from '../../commonComponents/uiComponents/LDModal';
import {toCapitalize} from '../../commonComponents/common/utils';
import { Alert, Snackbar, Tooltip } from "@mui/material";
import _ from 'lodash';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import { WALMART_NONPROFITS_URL } from '../../constants/constants';

const config = require(`../../../.././config.${process.env.NODE_ENV}.json`);
const service: Service = SpaceDonationService;

interface UserAgreementType {
    name: string;
    link: string;
    prefixText: string;
    postfixText: string;
}

export interface SpaceDonationScreenConfirmationProps {
    nextPage?: any,
    prevPage?: any,
    dateTime: any,
    spaceDetails: any,
    slotDetails: any,
    storeDetails: any,
    eventDetails: any,
    questionForm: any,
    spaceDonationSpaceChooser: any,
    spaceDonationStoreChooser: any,
    spaceDonationSlotChooser: any,
    setSelectedForm: any,
    history: any,
    userContext: any,
    customContact: any,
    customContactSelected: any,
}

interface ToasterMsgBannerProps {
    open: boolean;
    handleClose?: () => void;
    message: string,
}

export const ToasterMsgBanner : React.FC<ToasterMsgBannerProps> = ({ open, handleClose,message  }) => {
    return (
        <Snackbar open={open}  onClose={handleClose} autoHideDuration={3000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
            <Alert onClose={handleClose} severity={'success'}>
                {message}
            </Alert>
        </Snackbar>
    )
}

const getPhoneNumber = ((num:any)=>{
    let match:any = false;
    if(num?.length === 10){
        match = num?.match(/^(\d{3})(\d{3})(\d{3,4})$/);
    }
    if (match) {
       return `+1 (${match[1]})${match[2]}-${match[3]}`;
    }
    return num;
})

const SpaceDonationScreenConfirmation: React.SFC<SpaceDonationScreenConfirmationProps> = (props) => {
    const userContext: any = useContext(UserContext);
    const orgContactList = userContext?.organization?.orgContactList;
    const contact = props?.eventDetails?.contact;
    const contactFname = contact && contact.includes('####') ? contact.split('####')[0] : '';
    const contactLname = contact && contact.includes('####') ? contact.split('####')[1] : '';
    const isCustomContact = typeof contact === 'string' && contact.split('####')[2] === 'sc';
    const contactDetails = isCustomContact ? props?.customContact
        .filter((z: any) => z.firstName === contactFname && z.lastName === contactLname)[0] :
    orgContactList?.filter((z: any) => z.firstName === contactFname && z.lastName === contactLname)[0];
    const [userAgreementArr, setUserAgreementArr] = useState<UserAgreementType[]>([]);
    const [spaceLoc, setSpaceLoc] = useState('');
    const [spaceLists, setSpaceList] = useState([]);
    const [file, setFile] = useState<any>(null);
    const [certificateOfRegistration, setCertificateOfRegistration] = useState('');
    const [successFileUpload,setSuccessFileUpload] = useState(false);
    const [checkedStates, setCheckedStates] = useState<boolean[]>([]);    
    const { data:fetchSpaceLocation, isFetching : fetchingSpaceLocation , isSuccess: spaceLocationSuccess } = useQuery([props?.storeDetails?.storeNbr, 'US'],
    service.fetchSpaceAreas, { refetchOnWindowFocus: false });
    const dataObj = {
        state : props.storeDetails.state,
        spaceLocation: spaceLoc
    }
    const { data:userAgreementData,isSuccess: userAgreementDataSuccess } = useQuery<UserAgreementType[]>(
        'userAgreements',
        () => service.userAgreements(dataObj),
        {
          enabled :  !!(props.storeDetails.state && spaceLoc)
        }
    );
    const mutation = useMutation((data: any) => service.downloadProjectFile({
        queryKey: ['', {
            fileId: data.fileId,
            container: 'space-donation',
            directory: data.directory,
            type: data.type
        }]
    }));

    const saveUploadedFileMutation = useMutation((data: any) => service.saveCertificate(data));

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        const maxSize = 100 * 1024 * 1024;
        if (selectedFile && selectedFile?.size > maxSize) {
            alert('File size exceeds 100 MB. Please select a smaller file.');
            event.target.value = '';
            return;
        }

        if (file && selectedFile) {
            if (window.confirm('Do you want to replace the existing file?')) {
                saveUploadedFileMutation.mutate({
                    file: selectedFile,
                    spaceOrganizationId: userContext?.organization?.id,
                    countryCode: 'US',
                    fileType: 'certificate'
                });
                setFile(selectedFile);
            } else {
                event.target.value = '';
            }
        } else if (selectedFile) {
            saveUploadedFileMutation.mutate({
                file: selectedFile,
                spaceOrganizationId: userContext?.organization?.id,
                countryCode: 'US',
                fileType: 'certificate'
            });
            setFile(selectedFile);
        }
        };


    useEffect(() => {
    if (saveUploadedFileMutation.isSuccess) {
       const data = saveUploadedFileMutation.data;
       if (data.fileName) {
        const fileInfo = {
            fileId: data?.fileName,
            fileName:data?.fileName,
            directory: data?.uploadDir,
            container: data?.container
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `${config.appBaseUrl}/files?fileInfo=${encryptedFileInfo}`;
        setCertificateOfRegistration(dataUri);
        setSuccessFileUpload(true);
    }
    else if ((saveUploadedFileMutation.isError))
    {
        alert('Something Went Wrong!!');
    }
    }
    }, [saveUploadedFileMutation.isLoading]);


    useEffect(() => {
        if (spaceLocationSuccess && fetchingSpaceLocation && fetchSpaceLocation) {
          const res = _.groupBy(fetchSpaceLocation, (d: any) => d.location);
          const result: any[] = [];
          Object.keys(res).forEach((key) =>
            result.push({ header: key, spaces: res[key] })
          );
          setSpaceList(result as any);
          if (result.length > 0) {
            setSpaceLoc(result[0]?.header?.toUpperCase() || '');
          }
        }
      }, [spaceLocationSuccess, fetchingSpaceLocation, fetchSpaceLocation,spaceLists]);

    useEffect(() => {
        if (mutation.isSuccess) {
            const a = document.createElement('a');
            a.href = mutation.data;
            a.setAttribute('download', mutation.variables.fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if(userAgreementDataSuccess)
        {
            setUserAgreementArr(userAgreementData)
            setCheckedStates(Array(userAgreementData.length).fill(false));
        }
    }, [userAgreementData,userAgreementDataSuccess]);

    const handleCheckboxClick = (index: number) => {
        const newCheckedStates = [...checkedStates];
        newCheckedStates[index] = !newCheckedStates[index];
        setCheckedStates(newCheckedStates);
    };

    const areAllCheckboxesChecked = () => {
        if (props?.storeDetails?.state === 'MS') {
            if (file !== null && checkedStates.length === userAgreementArr.length) {
                return checkedStates.every(isChecked => isChecked);
            }
        } else {
            if (checkedStates.length === userAgreementArr.length) {
                return checkedStates.every(isChecked => isChecked);
            }
        }
        return false;
    }; 

    return <div className='reservation-card'>
        {successFileUpload && 
        <ToasterMsgBanner
        open={saveUploadedFileMutation.isSuccess}
        message=' File Uploaded Successfully..'
        handleClose={saveUploadedFileMutation.reset}
        />
        }
        <Card className='reservation-confirmation-content-card'>
            <CardItem >
                <div className='row'>
                    <div className='col-12 reservation-card-section-headers-1 black-text'>Review Your Request</div>
                    <div className='col-12 reservation-card-section-headers-2'>
                        Once you submit your request you will not be able to make changes
                    </div>
                </div>
            </CardItem>
            <CardItem className='reservation-confirmation-content-card-date-time'>
                <div className='reservation-confirmation-content-card-header-section'>
                    <div className='reservation-confirmation-content-card-header-icon'><Calendar /></div>
                    <div className='reservation-confirmation-content-card-header'>Date and Time</div>
                    <div
                        className='reservation-card-edit-link'
                        onClick={() => props.setSelectedForm(props.spaceDonationSlotChooser)}>Edit</div>
                </div>

                <div className='reservation-confirmation-content-card-details-setion'>
                    <div />
                    <div className='reservation-confirmation-content-card-details'>
                        <div>{moment(props.dateTime).format('MMM Do, YYYY')} ({props?.storeDetails?.timeZoneCode})</div>
                        {props.slotDetails.sort((a: any, b: any) => a.slotId - b.slotId).map((slot: any) => {
                            return <div key={slot.name}>{slot.label}</div>
                        })}
                        <br />
                    </div>
                </div>

            </CardItem>
            <Divider />
            <CardItem className='reservation-confirmation-content-card-space-section'>
                <div className='reservation-confirmation-content-card-header-section'>
                    <div className='reservation-confirmation-content-card-header-icon'><Store /></div>
                    <div className='reservation-confirmation-content-card-header'>Store and Space Location</div>
                    <div
                        className='reservation-card-edit-link'
                        onClick={() => props.setSelectedForm(props.spaceDonationStoreChooser)}>Edit</div>
                </div>

                <div className='reservation-confirmation-content-card-details-setion'>
                    <div />
                    <div className='reservation-confirmation-content-card-details'>
                        <div>{props.storeDetails.city} - {props.storeDetails.storeNbr}</div>
                        <div>{props.storeDetails.addressLine1}</div>
                        <div>{props.spaceDetails.name}</div>
                        <br />
                    </div>
                </div>

            </CardItem>
            <Divider />

            <CardItem className="reservation-confirmation-content-card-space-section">
            <div className="reservation-confirmation-content-card-header-section">
              <div className="reservation-confirmation-content-card-header-icon">
                <Phone />
              </div>
              <div className="reservation-confirmation-content-card-header">
              Event Contact Information &nbsp;
                <Tooltip
                  title={
                    <div className="tooltip-contacts-wrapper">
                      <span className="tooltip-contacts">
                        Only the <strong>Organization Contact(s)</strong> are
                        able to modify or cancel reservations in the Spark Good
                        Space Request Tool at{" "}
                        <a
                          href={WALMART_NONPROFITS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.walmart.com/nonprofits
                        </a>
                        . If you need to update the organization contact, please
                        make changes to your DEED account.
                      </span>
                      <br />
                      <span className="tooltip-contacts">
                        <strong>Event Contact(s)</strong> listed for the event
                        are only able to check-in and check-out for the event
                        and cannot make modifications to the event and cancel
                        the reservation.
                      </span>
                    </div>
                  }
                  placement="right"
                >
                  <span>
                    <InfoCircle className="icon-info-contacts" />
                  </span>
                </Tooltip>
              </div>
              <div
                className="reservation-card-edit-link"
                onClick={() => props.setSelectedForm(props.questionForm)}
              >
                Edit
              </div>
            </div>

                <div className='reservation-confirmation-content-card-details-setion'>
                    <div />
                    <div className='reservation-confirmation-content-card-details'>
                    {(props?.eventDetails?.contact || []).map((contactDetails:any,index:any) => (
                    <div key={index}>
                    <div>{toCapitalize(contactDetails?.firstName)} {toCapitalize(contactDetails?.lastName)}</div>
                    <div>{contactDetails?.email}</div>
                    <div>{getPhoneNumber(contactDetails?.phoneNumber)}</div>
                    <br/>
                    </div>
                    ))}
                </div>
                </div>

            </CardItem>
            <Divider />
            <CardItem className='reservation-confirmation-content-card-space-section'>
                <div className='reservation-confirmation-content-card-header-section'>
                    <div className='reservation-confirmation-content-card-header-icon'><Note /></div>
                    <div className='reservation-confirmation-content-card-header'>Event Details</div>
                    <div
                        className='reservation-card-edit-link'
                        onClick={() => props.setSelectedForm(props.questionForm)}>Edit</div>
                </div>

                <div className='reservation-confirmation-content-card-details-setion'>
                    <div />
                    <div className='reservation-confirmation-content-card-details'>
                        <div>{props?.eventDetails?.purpose}</div>
                        <div>{props?.eventDetails?.focusArea}</div>
                        <div>{props?.eventDetails?.aboutEvent}</div>
                        <div>{props?.eventDetails?.walmartPropertyDetails}</div>
                        <div>{props?.eventDetails?.exchangeDonationDetails}</div>
                        <br />
                    </div>
                </div>

            </CardItem>
            <Divider />
            <CardItem>
                <div className='reservation-confirmation-content-card-header'>Accept User Agreements</div>
                <div className='file-upload-section-header'>Walmart is committed to maintaining an efficient and safe working and shopping environment for our associates and customers.</div>
                    <div className='file-upload-section-header'>Please review and agree to the following policies to complete your space request.</div>
                    {userAgreementArr && userAgreementArr.map((item, index) => (
                        <div className='reservation-confirmation-content-policy' key={index}>
                            <LDCheckbox
                                checked={checkedStates[index]}
                                data-for='policy1'
                                data-tip={true}
                                onChange={()=>{
                                    if (!item?.link) {
                                        handleCheckboxClick(index);
                                    }}}
                                label={<div>
                                    <span>{item?.prefixText}</span>
                                    <a
                                        className='reservation-confirmation-content-policy-name'
                                        target='_blank'
                                        href={item?.link}
                                        rel='noreferrer'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleCheckboxClick(index);
                                        }}>
                                        {item?.name}
                                    </a>
                                    {item?.postfixText && <span>{item?.postfixText}</span>}
                                </div>}
                            />
                        </div>
                    ))}
        {props?.storeDetails?.state === 'MS' && 
            <div>
                <div className='file-upload-section-header'><b>Charitable Solicitations -</b> If your group will be soliciting charitable contributions, including the sale of goods for charitable purposes, by signing this form you certify that your group has either registered with the Mississippi Secretary State for that purpose or has been granted an exemption from registration by the Secretary of State, and you must provide a copy of your registration or exemption certificate before you may engage in solicitation activities on Walmart property.*</div>
                <div className='file-upload-container-confirmation-screen'>
                    <input type="file" onChange={handleFileChange} className='mississippi-file-upload-section' accept='.xlsx, .xls, .doc, .docx, .pdf, .jpeg, .jpg, .png' />
                </div>
            </div>
            }
            </CardItem>
            <CardItem className='reservation-confirmation-content-card-buttons confirmation-screen-desktop-button-section'>
                <div className='reservation-confirmation-content-card-buttons-cancel' />
                <div className='reservation-confirmation-content-card-buttons-checkIn'>
                    <ConfirmButton enableSubmit={areAllCheckboxesChecked()} {...props}
                       contactDetails={contactDetails}
                       certificateOfRegistration = {certificateOfRegistration}
                       orgDetails={userContext?.organization} className='space-button' />
                </div>
            </CardItem>
            <div className='phone-buffer-section' />
        </Card>
        <CardItem className='confirmation-screen-phone-button-section'>
                    <ConfirmButton enableSubmit={areAllCheckboxesChecked()} {...props}
                        contactDetails={contactDetails}
                        certificateOfRegistration = {certificateOfRegistration}
                        orgDetails={userContext?.organization}
                        className='confirmation-screen-phone-button space-button' />
        </CardItem>
    </div>
}

export default SpaceDonationScreenConfirmation;


const getRequestObject = (props: any) => {
    const tz = props.storeDetails.timeZoneCode ? props.storeDetails.timeZoneCode : DEFAULT_TIME_ZONE;
    return {
        isDeleted: false,
        donationType: 'SPACE_DONATION',
        countryCode: 'US',
        marketCode: 'US',
        storeTimeZone: tz,
        eventStartDateString:`${moment(props.dateTime).format('YYYY-MM-DD')}`,
        eventEndDateString:`${moment(props.dateTime).format('YYYY-MM-DD')}`,
        eventStartDate: getLocalTimeFromStoreTimeZone
            (`${moment(props.dateTime).format('YYYY-MM-DD')}T00:00:00`, 'YYYY-MM-DDTHH:mm:ssZ', tz.toUpperCase()),
        eventEndDate: getLocalTimeFromStoreTimeZone
            (`${moment(props.dateTime).format('YYYY-MM-DD')}T00:00:00`, 'YYYY-MM-DDTHH:mm:ssZ', tz.toUpperCase()),
        spaceOrganizationId: props.orgDetails.id,
        spaceOrganizationName: props.orgDetails.orgName,
        isOrgVerified: props.orgDetails.isVerified,
        isWalmartVerified: props.orgDetails?.isWalmartVerified,
        storeNbr: props.storeDetails.storeNbr,
        spaceLocation: props.spaceDetails.name,
        eventPurpose: props.eventDetails.purpose,
        focusArea: props.eventDetails.focusArea,
        storeFiltersApplied: [],
        eventSlots: props.slotDetails.map((slot: any) => ({
            ...slot,
            slotStartTime: getLocalTimeFromStoreTimeZone(`${moment(props.dateTime).format('YYYY-MM-DD')}T${slot.slotStartTime}`,
                'YYYY-MM-DDTHH:mm:ssZ',
                tz.toUpperCase()),
            slotEndTime: getLocalTimeFromStoreTimeZone(`${moment(props.dateTime).format('YYYY-MM-DD')}T${slot.slotEndTime}`,
                'YYYY-MM-DDTHH:mm:ssZ',
                tz.toUpperCase())
        })),
        aboutEvent: props.eventDetails.aboutEvent,
        walmartPropertyDetails:  props.eventDetails.walmartPropertyDetails,
        exchangeDonationDetails: props.eventDetails.exchangeDonationDetails,
        isSellingOrGivingAway: props.eventDetails.isSellingOrGivingAway,
        cybergrantsOrganizationId: null,
        eventContacts: (props?.eventDetails?.contact || []).map((contactDetails:any) => {
            return {
                firstName: contactDetails?.firstName,
                lastName: contactDetails?.lastName,
                email: contactDetails?.email,
                phoneNumber: contactDetails?.phoneNumber?.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '').substring(1),
                sendSMSUpdates: false,
                sendEmailUpdates: true,
                isCustomContact: contactDetails?.isOrgOwner ? false : true,
            }
        }),
        spaceLocationDetails: props.spaceDetails,
        storeInfo: {
            storeNbr: props.storeDetails.storeNbr,
            storeName: props.storeDetails.storeName,
            state: props.storeDetails.state,
            city: props.storeDetails.city,
            postalCode: props.storeDetails.postalCode,
            addressLine1: props.storeDetails.addressLine1,
            timeZoneCode: props.storeDetails.timeZoneCode,
            dstTimeZoneCode: props.storeDetails.dstTimeZoneCode,
            isDSTObservable: props.storeDetails.isDSTObservable
        },
        checkInDateTime: getLocalTimeFromStoreTimeZone(`${(moment(props.dateTime).format('YYYY-MM-DD') + 'T' + props.slotDetails
            .sort((a: any, b: any) => a.slotId - b.slotId)[0].slotStartTime)}`,
            'YYYY-MM-DDTHH:mm:ssZ',
            tz.toUpperCase()),
        checkOutDateTime: getLocalTimeFromStoreTimeZone(`${(moment(props.dateTime).format('YYYY-MM-DD') + 'T' + props.slotDetails
            .sort((a: any, b: any) => a.slotId - b.slotId)[props.slotDetails.length - 1].slotEndTime)}`,
            'YYYY-MM-DDTHH:mm:ssZ',
            tz.toUpperCase()),
        type: 'Event',
        requestInitiator: 'NGO',
        eventFrequency: 'Once',
        active: true,
        systemGenerated: false,
        certificateOfRegistration: props.certificateOfRegistration
    }
}

const ConfirmButton = (props: any) => {
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);
    const mutation: any = useMutation((data: any) => service.saveEvent(data));
    useEffect(() => {
        if (mutation.isSuccess) {
            props.history.push(
                {
                    pathname: `${contextPath}/space-donation/create/success`,
                    state: { id: mutation.data.id ,
                    isVerified: props.orgDetails.isVerified}
                })
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            setShowErrorPopUp(true);
        }
    }, [mutation.isError]);

    return <> {mutation.isLoading && <LoaderComponent />}
        {showErrorPopUp && <ErrorModalCard error={mutation?.error?.response?.data?.errorMessage}
            isOpen={showErrorPopUp}
            setIsOpen={setShowErrorPopUp} />}
        <Button className={props.className}
            disabled={!props.enableSubmit}
            onClick={() => mutation.mutate(getRequestObject(props))}
            variant='primary'>
            Request my space</Button></>
}

const ErrorModalCard: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <div className='checkin-message'>
            <CloseCircleFill className='error-icon' />
            <h3> Sorry!</h3>
            <br />
            {props.error}
            <br />
            <Button
                onClick={() => props.setIsOpen(false)} variant='secondary'>
                Back
            </Button>
        </div>
    </Modal>
}
