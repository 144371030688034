import * as React from 'react';
import './style.css';
import { LITERATURE_RULE } from '../../../constants/constants';
class FooterComponentSpace extends React.PureComponent {
    public render() {
        return (<>
            <div className='app-footer-space'>
                <div className='links-wrapper-footer'>
                    <a target='_blank'
                        rel='noreferrer'
                        // tslint:disable-next-line:max-line-length
                        href={LITERATURE_RULE}>
                        Terms of Use and Notice of Solicitation and Distribution of Literature Rules - National </a>
                </div>
                {/*<p>© Walmart Stores, Inc.</p>*/}
            </div>
        </>);
    }
}

export default FooterComponentSpace;
