import React, { useContext } from 'react';
import SideNavigation from '../../uiComponents/LDSideNavigation';
import SideNavigationItem from '../../uiComponents/LDSideNavigationItem';
import { Calendar, User, InfoCircle, SignOut, Close,  Note, Article } from '../../icons/icon';
import UserName from '../userName/userName';
import { contextPath } from 'src/constants';
import { Link } from 'react-router-dom';
import UserContext from '../../../store/user-context';
import './styles.css';
import { PROGRAM_GUIDELINES_URL, WALMART_FAQ_URL } from '../../../../../components/spacedonation/constants/constants'

const sidebarData = [{
    'id': 0,
    'icon': <Note />,
    'href': `${contextPath}/space-donation/create`,
    'name': 'Request space'
}, {
    'id': 1,
    'icon': <Calendar />,
    'href': `${contextPath}/space-donation/events`,
    'name': 'Events'
}, {
    'id': 2,
    'icon': <User />,
    'href': `${contextPath}/space-donation/account`,
    'name': 'Account'
}, {
    'id': 3,
    'icon': <InfoCircle />,
    'href': `${contextPath}/space-donation/faq`,
    'name': 'FAQs'
}, {
    'id': 4,
    'icon': <Article />,
    'href': `${contextPath}/space-donation/program-guidelines`,
    'name': 'Program Guidelines'
}, {
    'id': 5,
    'icon': <SignOut />,
    'href': `${contextPath}/space-donation/signout`,
    'name': 'Signout'
}]


// tslint:disable-next-line
export interface SideBarProps {
}

const isCurrentMenu = (data: any) => {
    return window.location.href.includes(sidebarData.filter(x => x.id === data.id)[0].href)
}

const isMobileResolution = () => window.innerWidth <= 425;

const SideBar: React.SFC<SideBarProps> = (props: any) => {
    const userContext = useContext(UserContext);
    // @ts-ignore
    const userName = `${userContext?.organization?.userContactDetails?.firstName} ${userContext?.organization?.userContactDetails?.lastName}`
    return <>
        <div style={{ 'padding': '5px 2px', 'fontWeight': 300, 'fontFamily': 'Bogle !important', 'fontSize': '1rem' }}>

            <div className='side-bar-close-button-section'>
                <Close onClick={() => props.navBarClose()} /></div>
            <div className='side-bar-header-section'>
                <UserName name={userName !== 'undefined undefined' ? userName.toUpperCase() : ''} />
            </div>
            <SideNavigation>
                {sidebarData.map((data) => {
                    if (data.name === 'FAQs') {
                        return (
                            <SideNavigationItem
                                key={data.name}
                                // @ts-ignore
                                // href={data.href}
                                isCurrent={isCurrentMenu(data)}
                                onClick={() => {
                                    window.open(WALMART_FAQ_URL);
                                    isMobileResolution() ? props.navBarClose() : isMobileResolution();
                                }}
                                leading={data.icon}>
                                <div>{data.name}</div>
                            </SideNavigationItem>
                        )
                    }
                    else if (data.name === 'Program Guidelines') {
                        return (
                            <SideNavigationItem
                                key={data.name}
                                isCurrent={isCurrentMenu(data)}
                                onClick={() => {
                                    window.open(PROGRAM_GUIDELINES_URL);
                                    isMobileResolution() ? props.navBarClose() : isMobileResolution();
                                }}
                                leading={data.icon}>
                                <div>{data.name}</div>
                            </SideNavigationItem>
                        )
                    }
                    return <Link
                        key={data.name}
                        to={data.href}>
                        <SideNavigationItem
                            key={data.name}
                            // @ts-ignore
                            href={data.href}
                            isCurrent={isCurrentMenu(data)}
                            onClick={() => {
                                isMobileResolution() ? props.navBarClose() : isMobileResolution()
                            }}
                            leading={data.icon}>
                            <div>{data.name}</div>
                        </SideNavigationItem>
                    </Link>
                })}
            </SideNavigation>
        </div></>
}

export default SideBar;
