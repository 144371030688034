import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import OnboardingTracker from "../../../commonComponents/appComponents/onboardingTracker/onboardingTracker";
import Card from "../../../commonComponents/uiComponents/LDCard";
import CardItem from "../../../commonComponents/uiComponents/LDCardItem";
import Button from "../../../commonComponents/uiComponents/LDButton";
import LDTextField from "../../../commonComponents/uiComponents/LDTextField";
import {
  validateEmail,
  isNotEmpty,
  isOnlyNumbers,
  toCapitalize,
  validatePhoneNumber,
  isValidUrl,
} from "../../../commonComponents/common/utils";
import "./styles.css";
import Axios from "axios";
import OnboardingLoaderComponent from "../../../commonComponents/appComponents/loader/onBoardingLoader";
import LoaderComponent from "../../../commonComponents/appComponents/loader/LoaderComponent";
import SpaceDonationService, {
  Service,
} from "../../../../../spaceDonationService";
import LDSelect from "../../../commonComponents/uiComponents/LDSelect";
import { contextPath } from "src/constants";
import LDModal from "../../../commonComponents/uiComponents/LDModal";
import { useForm, Controller } from "react-hook-form";
import Select from "../../../commonComponents/uiComponents/LDSelect";
import analytics from "../../../../../adobe-analytics/analytics";
import spacetracker from "../../../../../adobe-analytics-space/space-tracker";
import { SPACEANALYTICS } from "../../../../../adobe-analytics-space/analytics-space-dataelements";
import { WALMART_URL } from '../../../constants/constants';

const index = "index";
const back = "Back";
const complete = "Complete";
const config = require(`src/config.${process.env.NODE_ENV}.json`);

const pageData: any = {
  tabs: {
    account: { name: "account", index: 0, heading: "" },
    organization: {
      name: "organization",
      index: 1,
      heading: "Add organization details",
    },
    contacts: {
      name: "contacts",
      index: 2,
      heading: "Add a secondary contact",
    },
  },
};
const postVerifiedDetails = (postData: any) => {
  return Axios.post(
    `${config.appBaseUrl}/space-donations/organizations`,
    postData
  ).then((response) => response.data);
};

export interface Props {
  history?: any;
  contextPath?: any;
  isFetching?: any;
  data?: any;
  activeForm?: any;
  setInputPhoneNum?: any;
  phone?: any;
  organization?: any;
  setOrganization?: any;
  usStates?: any;
  setActiveForm?: any;
  setEmergencyContact?: any;
  emergencyContact?: any;
}

const service: Service = SpaceDonationService;

const NonVerifiedOnBoarding = (props: Props) => {
  const { data, isFetching } = useQuery(
    ["fetchUsStates", { key: "usStates", countryCode: "US" }],
    service.getSpaceDonationConfigurations,
    { refetchOnWindowFocus: false }
  );
  const [phone, setInputPhoneNum] = useState("");
  const [organization, setOrganization] = useState(props.organization);
  const [emergencyContact, setEmergencyContact] = useState();
  return isFetching ? (
    <LoaderComponent />
  ) : (
    <NonVerifiedOnBoardingSection
      {...props}
      organization={organization}
      phone={phone}
      setInputPhoneNum={setInputPhoneNum}
      setOrganization={setOrganization}
      usStates={data}
      setEmergencyContact={setEmergencyContact}
      emergencyContact={emergencyContact}
    />
  );
};
export default NonVerifiedOnBoarding;

const NonVerifiedOnBoardingSection = (props: Props) => {
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);

  const [activeForm, setActiveForm] = useState(pageData.tabs.account.name);

  const queryClient = useQueryClient();
  const [, setError] = useState(false);
  const [, setSuccess] = useState(false);

  useEffect(() => {
    if (props.emergencyContact && activeForm === pageData.tabs.contacts.name) {
      onComplete();
    }
  }, [props.emergencyContact]);

  const { mutate, isLoading } = useMutation(postVerifiedDetails, {
    onSuccess: () => {
      console.log(props.organization.organization);
      setShowSuccessPopUp(true);
      analytics.trackUnVerifiedLogins(props.organization.organization);
      setSuccess(true);
    },
    onError: () => {
      setError(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const onSubmitPopUpClose = () => {
    setShowSuccessPopUp(false);
    props.history.push(`${contextPath}/space-donation/create`);
  };

  const onSubmit = () => {
    analytics.trackEventInfo({
      type: spacetracker.EVENTS.CLICK,
      actionOn: spacetracker.ACTION_ON.NON_VERIFIED_CONTACT_INFO_COMPLETE.name,
      location: SPACEANALYTICS.onboardingSpaceExternalPage.name,
    });
  };

  const onComplete = () => {
    const postBody = {
      isVerified: false,
      onboardingStatus: props.data.onboardingStatus,
      cybergrantsOrganizationId: props.data.cybergrantsOrganizationId,
      onboarded: true,
      onboardedDtm: new Date(),
      // 'orgPhoneNumber': props.data.orgPhoneNumber,
      isForProfit: props.organization.isForProfit,
      orgName: props.organization.organization,
      focusArea: props.organization.focusArea,
      orgEntity: props.organization.orgEntity,
      website: props.organization.website,
      orgAddressLine1: props.organization.address1,
      orgAddressLine2: props.organization.address2,
      orgCity: props.organization.city,
      orgState: props.organization.state,
      orgZip: props.organization.zipcode,
      contactList: [
        {
          cid: props.data.userId,
          firstName: props.data.firstName,
          lastName: props.data.lastName,
          email: props.data.email,
          phoneNumber: props?.phone
            ?.replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/\s/g, "")
            .substring(1),
          permissionToEmail: true,
          permissionToSMS: false,
          isOrgOwner: true,
        },
        {
          cid: null,
          firstName: props.emergencyContact.fname2,
          lastName: props.emergencyContact.lname2,
          email: props.emergencyContact.email2,
          phoneNumber: props.emergencyContact.phone2
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/\s/g, "")
            .substring(1),
          permissionToEmail: true,
          permissionToSMS: false,
          isOrgOwner: false,
        },
      ],
      smsPhoneNumber: "",
      agreeToTerms: true,
      flags: [],
    };
    mutate(postBody);
  };
  return (
    <>
      <div className="info-card-container">
        <LDModal
          title=" "
          className="modal-apply-blur"
          size="small"
          onClose={() => onSubmitPopUpClose()}
          isOpen={showSuccessPopUp}
          setIsOpen={setShowSuccessPopUp}
        >
          <div>
            Thank you! We will review your application and provide an update on
            your status soon.
          </div>
          <div>
            <Button
              className="verification-buttons"
              onClick={() => onSubmitPopUpClose()}
              variant="tertiary"
            >
              Close
            </Button>
          </div>
        </LDModal>

        <div className="onboarding-tracker-container">
          {
            <OnboardingTracker
              progressTrackerItems={Object.keys(pageData.tabs)}
              activeIndex={pageData.tabs[activeForm][index]}
              variant={"info"}
            />
          }
        </div>
        <br />
        <div>
          {activeForm === pageData.tabs.account.name && (
            <Account
              activeForm={activeForm}
              data={props.data}
              setInputPhoneNum={props.setInputPhoneNum}
              phone={props.phone}
              setActiveForm={setActiveForm}
              nextTab={pageData.tabs.organization.name}
              prevTab=""
            />
          )}

          {activeForm === pageData.tabs.organization.name && (
            <Organization
              activeForm={activeForm}
              setOrganization={props.setOrganization}
              organization={props.organization}
              setActiveForm={setActiveForm}
              nextTab={pageData.tabs.contacts.name}
              prevTab={pageData.tabs.account.name}
              usStates={props.usStates}
            />
          )}

          {activeForm === pageData.tabs.contacts.name && (
            <EmergencyContacts
              activeForm={activeForm}
              setActiveForm={setActiveForm}
              nextTab=""
              data={props.data}
              submit={onSubmit}
              prevTab={pageData.tabs.organization.name}
              history={props.history}
              contextPath={props.contextPath}
              {...props}
            />
          )}
        </div>
      </div>
      {isLoading ? <OnboardingLoaderComponent /> : <></>}
    </>
  );
};

export interface CardProps {
  nextTab: string;
  prevTab: string;
  setActiveForm: any;
  activeForm: any;
  history?: any;
  contextPath?: any;
  data?: any;
  setInputPhoneNum?: any;
  phone?: any;
  organization?: any;
  setOrganization?: any;
  usStates?: any;
  submit?: any;
}

const Account: React.SFC<CardProps> = (props: any) => {
  let match;
  const containsNumbersOnly = /^\d+$/.test(props?.phone);
  if (props?.phone?.length === 10) {
    match = props?.phone?.match(/^(\d{3})(\d{3})(\d{3,4})$/);
  }
  if (match) {
    props.setInputPhoneNum(
      "+1 (" + match[1] + ") " + match[2] + "-" + match[3]
    );
  }
  if (!containsNumbersOnly && props?.phone?.length <= 10) {
    props.setInputPhoneNum(props?.phone.replace(/\D/g, ""));
  }
  if (props?.phone?.length > 17) {
    props.setInputPhoneNum(props?.phone.substr(0, 17));
  }
  return (
    <Card>
      <CardItem>
        <div className="info-card-header">
          Welcome, {toCapitalize(props.data.firstName)}
        </div>
        <div className="info-card-text">
          To get started, please review the details below. To edit your
          information, please log into your Walmart account.
        </div>
      </CardItem>
      <CardItem>
        <div className="info-card-input-div">
          <div className="info-card-input-button-div">
            <LDTextField
              label="First Name"
              disabled={true}
              type="text"
              value={toCapitalize(props.data.firstName)}
            />
          </div>
          <div className="info-card-input-button-div">
            <LDTextField
              label="Last Name"
              disabled={true}
              type="text"
              value={toCapitalize(props.data.lastName)}
            />
          </div>
        </div>
        <div>
          <LDTextField
            label="Email"
            disabled={true}
            type="email"
            value={props.data.email}
          />
        </div>
        <div>
          <LDTextField
            label="Mobile Phone Number"
            value={props.phone}
            onChange={(e: any) => props.setInputPhoneNum(e.target.value)}
            required={true}
            type="text"
          />
        </div>
      </CardItem>
      <div className="info-card-buttons">
        <div style={{ marginLeft: "2%", fontSize: "14px" }}>
          If this is your personal email, and you would like to use your
          organization email address to submit space requests, please create a
          separate{" "}
          <a href={WALMART_URL} target="_blank" rel="noreferrer">
            Walmart.com
          </a>{" "}
          account and log in using that account.
        </div>
        <Button
          disabled={!props.phone && props.phone === ""}
          onClick={() => props.setActiveForm(props.nextTab)}
          variant="primary"
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};

const Organization: React.SFC<CardProps> = (props: any) => {
  const { data: focusAreaData, isFetching: focusAreaFetching } = useQuery(
    ["fetchFocusAreas", { key: "focusArea", countryCode: "US" }],
    service.getSpaceDonationConfigurations,
    { refetchOnWindowFocus: false }
  );

  const { data: orgEntityData, isFetching: orgEntityFetching } = useQuery(
    ["fetchorgEntity", { key: "orgEntity", countryCode: "US" }],
    service.getSpaceDonationConfigurations,
    { refetchOnWindowFocus: false }
  );

  const [organization, setOrganization] = useState(props?.organization);
  const { control, handleSubmit, errors, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      isForProfit: organization?.isForProfit ? organization?.isForProfit : "",
      organization: organization?.organization
        ? organization?.organization
        : "",
      address1: organization?.address1 ? organization?.address1 : "",
      address2: organization?.address2 ? organization?.address2 : "",
      city: organization?.city ? organization?.city : "",
      state: organization?.state ? organization?.state : "",
      zipcode: organization?.zipcode ? organization?.zipcode : "",
      focusArea: organization?.focusArea ? organization?.focusArea : "",
      orgEntity: organization?.orgEntity ? organization?.orgEntity : "",
      website: organization?.website ? organization?.website : "",
    },
  });

  const isForProfit = ["Yes", "No"];
  const onNext = () => {
    setOrganization(getValues());
    props.setOrganization(getValues());
    props.setActiveForm(props.nextTab);
  };
  const orgRegContinue = () => {
    analytics.trackEventInfo({
      type: spacetracker.EVENTS.CLICK,
      actionOn: spacetracker.ACTION_ON.NON_VERIFIED_ORGANIZATION_CONTINUE.name,
      location: SPACEANALYTICS.onboardingSpaceExternalPage.name,
    });
  };
  const onBack = (tab: any) => {
    analytics.trackEventInfo({
      type: spacetracker.EVENTS.CLICK,
      actionOn: spacetracker.ACTION_ON.NON_VERIFIED_ORGANIZATION_BACK.name,
      location: SPACEANALYTICS.onboardingSpaceExternalPage.name,
    });
    setOrganization(getValues());
    props.setOrganization(getValues());
    props.setActiveForm(tab);
  };
  return (
    <Card>
      {(focusAreaFetching || orgEntityFetching) && <LoaderComponent />}
      <form onSubmit={handleSubmit(onNext)}>
        <CardItem>
          <div className="info-card-header">
            {pageData.tabs[props.activeForm].heading}
          </div>
          <div className="info-card-text">
            Please provide us some information about your organization.
          </div>
        </CardItem>
        <CardItem>
          <div>
            <Controller
              control={control}
              name="isForProfit"
              defaultValue={""}
              data-testid="isForProfit"
              as={
                <Select
                  label={
                    <div className="space-request-questions-card-answer-text">
                      Are you with a for-profit company and intend to solicit
                      customers to purchase services if approved for Space
                      outside of a Walmart facility?
                    </div>
                  }
                >
                  <option selected={true} value="">
                    {" "}
                    --Select An Option--{" "}
                  </option>
                  {isForProfit?.map((pc: any) => {
                    return (
                      <option key={pc} value={pc}>
                        {pc}
                      </option>
                    );
                  })}
                </Select>
              }
              rules={{
                required: "Required Field",
                validate: (value: any) => {
                  return isNotEmpty(value, "Required Field");
                },
              }}
            />
            {errors?.isForProfit?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.isForProfit?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="organization"
              defaultValue={""}
              data-testid="organization"
              as={
                <LDTextField
                  label="Organization"
                  value={props.organization}
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: "Organization name is required",
                validate: (value: any) => {
                  return isNotEmpty(value, "Organization name is required");
                },
              }}
            />
            {errors?.organization?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.organization?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="focusArea"
              defaultValue={""}
              data-testid="focusArea"
              as={
                <Select
                  label={
                    <div className="space-request-questions-card-answer-text">
                      Organization type
                    </div>
                  }
                >
                  <option selected={true} value="">
                    {" "}
                    --Select An Option--{" "}
                  </option>
                  {focusAreaData?.focusAreaList?.map((fa: any) => {
                    return (
                      <option key={fa} value={fa}>
                        {fa}
                      </option>
                    );
                  })}
                </Select>
              }
              rules={{
                required: "Organization type required",
                validate: (value: any) => {
                  return isNotEmpty(value, "Organization type is required");
                },
              }}
            />
            {errors?.focusArea?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.focusArea?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="orgEntity"
              defaultValue={""}
              data-testid="orgEntity"
              as={
                <Select
                  label={
                    <div className="space-request-questions-card-answer-text">
                      Organization Entity
                    </div>
                  }
                >
                  <option selected={true} value="">
                    {" "}
                    --Select An Option--{" "}
                  </option>
                  {orgEntityData?.orgEntityList?.map((fa: any) => {
                    return (
                      <option key={fa} value={fa}>
                        {fa}
                      </option>
                    );
                  })}
                </Select>
              }
              rules={{
                required: "Organization Entity required",
                validate: (value: any) => {
                  return isNotEmpty(value, "Organization Entity is required");
                },
              }}
            />
            {errors?.orgEntity?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.orgEntity?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="website"
              defaultValue={""}
              data-testid="website"
              as={
                <LDTextField
                  label="Organization Website Link"
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: "Organization website is required",
                validate: (value: any) => {
                  return (
                    isNotEmpty(value, "Organization website") &&
                    isValidUrl(value, "Please Provide a Valid Link")
                  );
                },
              }}
            />
            {errors?.website?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.website?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="address1"
              defaultValue={""}
              data-testid="address1"
              as={
                <LDTextField
                  label="Address"
                  value={props.address1}
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: "Address is required",
                validate: (value: any) => {
                  return isNotEmpty(value, "Address is required");
                },
              }}
            />
            {errors?.address1?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.address1?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="address2"
              defaultValue={""}
              data-testid="address2"
              as={
                <LDTextField
                  label="Address 2 (optional)"
                  value={props.address2}
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: false,
              }}
            />
            {errors?.address2?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.address2?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="city"
              defaultValue={""}
              data-testid="city"
              as={
                <LDTextField
                  label="City"
                  value={props.city}
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: "City name required",
                validate: (value: any) => {
                  return isNotEmpty(value, "City name is required");
                },
              }}
            />
            {errors?.city?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.city?.message}
              </span>
            )}
          </div>
          <div className="info-card-input-div">
            <div className="info-card-input-button-div">
              <Controller
                control={control}
                name="state"
                defaultValue={""}
                data-testid="state"
                as={
                  <LDSelect label="State" value={props.state} required={true}>
                    <option selected={true} hidden={true}>
                      {" "}
                      --Select An Option--{" "}
                    </option>
                    {props?.usStates?.us_states?.map((fa: any) => {
                      return (
                        <option key={fa.value} value={fa.value}>
                          {fa.value}
                        </option>
                      );
                    })}
                  </LDSelect>
                }
                rules={{
                  required: "State name required",
                  validate: (value: any) => {
                    return isNotEmpty(value, "State name is required");
                  },
                }}
              />
              {errors?.state?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.state?.message}
                </span>
              )}
            </div>
            <div className="info-card-input-button-div">
              <Controller
                control={control}
                name="zipcode"
                defaultValue={""}
                data-testid="zipcode"
                as={
                  <LDTextField
                    label="Zip Code"
                    value={props.zipcode}
                    required={true}
                    type="text"
                    textFieldProps={{ maxLength: "5", minLength: "5" }}
                  />
                }
                rules={{
                  required: "Zip code required",
                  validate: (value: any) => {
                    return (
                      isNotEmpty(value, "Zip code is required") &&
                      isOnlyNumbers(value, "Zip code can contain only numbers")
                    );
                  },
                }}
              />
              {errors?.zipcode?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.zipcode?.message}
                </span>
              )}
            </div>
          </div>
        </CardItem>
        <div className="info-card-buttons-spaced">
          <Button onClick={() => onBack(props.prevTab)}>{back}</Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => orgRegContinue()}
          >
            Continue
          </Button>
        </div>
      </form>
    </Card>
  );
};

const EmergencyContacts: React.SFC<CardProps> = (props: any) => {
  const [emergencyContact, setEmergencyContact] = useState(
    props?.emergencyContact
  );
  const { control, handleSubmit, errors, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      fname2: emergencyContact?.fname2 ? emergencyContact?.fname2 : "",
      lname2: emergencyContact?.lname2 ? emergencyContact?.lname2 : "",
      email2: emergencyContact?.email2 ? emergencyContact?.email2 : "",
      phone2: emergencyContact?.phone2 ? emergencyContact?.phone2 : "",
    },
  });
  const onSubmit = () => {
    setEmergencyContact(getValues());
    props.setEmergencyContact(getValues());
    props.submit();
  };
  const onBack = (tab: any) => {
    analytics.trackEventInfo({
      type: spacetracker.EVENTS.CLICK,
      actionOn: spacetracker.ACTION_ON.NON_VERIFIED_CONTACTS_BACK.name,
      location: SPACEANALYTICS.onboardingSpaceExternalPage.name,
    });
    setEmergencyContact(getValues());
    props.setEmergencyContact(getValues());
    props.setActiveForm(tab);
  };
  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardItem>
          <div className="info-card-header">
            {pageData.tabs[props.activeForm].heading}
          </div>
          <div className="info-card-text">
            Please add an alternate contact to this account.
          </div>
        </CardItem>
        <CardItem>
          <div className="info-card-input-div">
            <div className="info-card-input-button-div">
              <Controller
                control={control}
                name="fname2"
                defaultValue={""}
                data-testid="fname2"
                as={
                  <LDTextField
                    label="First Name"
                    type="text"
                    required={true}
                    value={toCapitalize(props.fname2)}
                  />
                }
                rules={{
                  required: "First name is required",
                  validate: (value: any) => {
                    return isNotEmpty(value, "First name is required");
                  },
                }}
              />
              {errors?.fname2?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.fname2?.message}
                </span>
              )}
            </div>
            <div className="info-card-input-button-div">
              <Controller
                control={control}
                name="lname2"
                defaultValue={""}
                data-testid="lname2"
                as={
                  <LDTextField
                    label="Last Name"
                    type="text"
                    required={true}
                    value={toCapitalize(props.lname2)}
                  />
                }
                rules={{
                  required: "Last name is required",
                  validate: (value: any) => {
                    return isNotEmpty(value, "Last name is required");
                  },
                }}
              />
              {errors?.lname2?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.lname2?.message}
                </span>
              )}
            </div>
          </div>
          <div>
            <Controller
              control={control}
              name="email2"
              defaultValue={""}
              data-testid="email2"
              as={
                <LDTextField
                  label="Email"
                  type="text"
                  required={true}
                  value={props.email2}
                />
              }
              rules={{
                required: "Email is required",
                validate: (value: any) => {
                  return validateEmail(value) ? true : "invalid email";
                },
              }}
            />
          </div>
          {errors?.email2?.message && (
            <span className="reg-form-error-container" style={{ color: "red" }}>
              {errors?.email2?.message}
            </span>
          )}
          <div>
            <Controller
              control={control}
              name="phone2"
              defaultValue={""}
              data-testid="phone2"
              as={
                <LDTextField
                  label="Mobile Phone Number"
                  value={props.phone2}
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: "Mobile Phone Number is required",
                validate: (value: any) => {
                  return validatePhoneNumber(value, setValue, "phone2");
                },
              }}
            />
          </div>
          {errors?.phone2?.message && (
            <span className="reg-form-error-container" style={{ color: "red" }}>
              {errors?.phone2?.message}
            </span>
          )}
        </CardItem>
        <div className="info-card-buttons-spaced">
          <Button onClick={() => onBack(props.prevTab)}>{back}</Button>
          <Button type="submit" variant="primary">
            {complete}
          </Button>
        </div>
      </form>
    </Card>
  );
};
