import React, { useState } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import Card from "../../../commonComponents/uiComponents/LDCard";
import CardItem from "../../../commonComponents/uiComponents/LDCardItem";
import Button from "../../../commonComponents/uiComponents/LDButton";
import Divider from "../../../commonComponents/uiComponents/LDDivider";
import LoaderComponent from "../../../commonComponents/appComponents/loader/LoaderComponent";
import OnboardingTracker from "../../../commonComponents/appComponents/onboardingTracker/onboardingTracker";
import LDTextField from "../../../commonComponents/uiComponents/LDTextField";
import SpaceDonationService, {
  Service,
} from "../../../../../spaceDonationService";
import Axios from "axios";
import Select from "../../../commonComponents/uiComponents/LDSelect";
import "./styles.css";
import OnboardingLoaderComponent from "../../../commonComponents/appComponents/loader/onBoardingLoader";
import {
  toCapitalize,
  validateWebsiteUrl,
} from "../../../commonComponents/common/utils";
import { DEED_LOGIN_URL, SPARKGOOD_VERIFY_URL } from '../../../constants/constants';

const service: Service = SpaceDonationService;

const config = require(`src/config.${process.env.NODE_ENV}.json`);
const index = "index";
const back = "Back";

export interface Props {
  history?: any;
  contextPath?: any;
  isFetching?: any;
  data?: any;
  activeForm?: any;
  setActiveForm?: any;
  setInputPhoneNum?: any;
  phone?: any;
}

export interface CardProps {
  setActiveForm: any;
  activeForm: any;
  history?: any;
  contextPath?: any;
  data?: any;
  submit?: any;
  setInputPhoneNum?: any;
  phone?: any;
  nextTab: string;
  prevTab: string;
  focusArea?: any;
  orgEntity?: any;
  website?: any;
  setFocusArea?: any;
  setOrgEntity?: any;
  setWebsite?: any;
}

const complete = "Complete";

const data: any = {
  tabs: {
    account: { name: "account", index: 0, heading: "" },
    organization: { name: "organization", index: 1, heading: "Organization" },
  },
};
const postVerifiedDetails = (postData: any) => {
  return Axios.post(
    `${config.appBaseUrl}/space-donations/organizations`,
    postData
  ).then((response) => response.data);
};

const VerifiedOnBoarding = (props: Props) => {
  const [activeForm, setActiveForm] = useState(data.tabs.account.name);
  const [phone, setInputPhoneNum] = useState(null);
  return props.isFetching ? (
    <LoaderComponent />
  ) : (
    <VerifiedOnboardingSection
      {...props}
      activeForm={activeForm}
      setActiveForm={setActiveForm}
      phone={phone}
      setInputPhoneNum={setInputPhoneNum}
    />
  );
};
export default VerifiedOnBoarding;

const VerifiedOnboardingSection: React.SFC<Props> = (props: any) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [focusArea, setFocusArea] = useState("");
  const [orgEntity, setOrgEntity] = useState("");
  const [website, setWebsite] = useState("");
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });
  console.log(error, success);
  const { mutate, isLoading } = useMutation(postVerifiedDetails, {
    onSuccess: () => {
      props.history.push({
        pathname: `${props.contextPath}/space-donation/create`,
        state: "",
      });
      setSuccess(true);
    },
    onError: () => {
      setError(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  console.log(isLoading, errors, register);
  const onSubmit = () => {
    const postBody = {
      isVerified: props.data.isVerified,
      onboardingStatus: props.data.onboardingStatus,
      orgId: props.data.orgId, // if the verified org already present then orgId will not null
      cybergrantsOrganizationId: props.data.cybergrantsOrganizationId,
      onboarded: true,
      onboardedDtm: new Date(),
      orgPhoneNumber: props.data.orgPhoneNumber,
      orgName: props.data.orgName,
      orgAddressLine1: props.data.orgAddressLine1,
      orgAddressLine2: props.data.orgAddressLine2,
      orgCity: props.data.orgCity,
      orgState: props.data.orgState,
      orgZip: props.data.orgZip,
      focusArea,
      orgEntity,
      website,
      contactList: [
        {
          cid: props.data.cid,
          firstName: props.data.primaryContactFname,
          lastName: props.data.primaryContactLname,
          email: props.data.primaryContactEmail,
          phoneNumber: props?.phone
            ?.replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/\s/g, "")
            .substring(1),
          isOrgOwner: props.data.orgOwner,
          permissionToEmail: true,
          permissionToSMS: false,
        },
      ],
      smsPhoneNumber: "",
      agreeToTerms: true,
      flags: [],
    };
    mutate(postBody);
  };

  return (
    <>
      <div className="info-card-container">
        {
          <OnboardingTracker
            progressTrackerItems={Object.keys(data.tabs)}
            activeIndex={data.tabs[props.activeForm][index]}
            variant={"info"}
          />
        }
        <br />
        <div>
          <form onSubmit={() => handleSubmit(onSubmit)}>
            {props.activeForm === data.tabs.account.name && (
              <Account
                activeForm={props.activeForm}
                setInputPhoneNum={props.setInputPhoneNum}
                phone={props.phone}
                setActiveForm={props.setActiveForm}
                data={props.data}
                nextTab={data.tabs.organization.name}
                prevTab=""
              />
            )}
            {props.activeForm === data.tabs.organization.name && (
              <Org
                activeForm={props.activeForm}
                setActiveForm={props.setActiveForm}
                data={props.data}
                nextTab=""
                prevTab={data.tabs.account.name}
                focusArea={focusArea}
                orgEntity={orgEntity}
                website={website}
                setFocusArea={setFocusArea}
                setOrgEntity={setOrgEntity}
                setWebsite={setWebsite}
                submit={() => handleSubmit(onSubmit)}
              />
            )}
          </form>{" "}
        </div>
      </div>
      {isLoading ? <OnboardingLoaderComponent /> : <></>}
    </>
  );
};

const Org: React.SFC<CardProps> = (props: any) => {
  const onBack = (tab: any) => {
    props.setActiveForm(tab);
  };
  const { data: focusAreaData, isFetching: focusAreaFetching } = useQuery(
    ["fetchFocusAreas", { key: "focusArea", countryCode: "US" }],
    service.getSpaceDonationConfigurations,
    { refetchOnWindowFocus: false }
  );

  const [isValidWebsite, setIsValidWebsite] = useState(true);

  const handleWebsiteUrl = (event: any) => {
    props.setWebsite(event.target.value);
    setIsValidWebsite(validateWebsiteUrl(event.target.value));
  };

  return (
    <Card>
      {focusAreaFetching && <LoaderComponent />}
      <CardItem>
        <div className="info-card-text">
          You are an authorized user of a Spark Good Account. To edit your
          organization details, please sign into your
          <a
            href={DEED_LOGIN_URL}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            DEED account
          </a>
          .
          <br />
        </div>
      </CardItem>
      <Divider />
      <CardItem>
        <div className="info-card-sub-header">Your organization</div>
        <div className="info-card-text">
          {props?.data?.orgName}
          <br />
          {props?.data?.orgAddressLine1}
          <br />
          {props?.data?.orgAddressLine2}
          <br />
          {props?.data?.orgCity}
          <br />
          {props?.data?.orgState}
          <br />
          {props?.data?.orgZip}
          <br />
          {props?.data?.orgPhone}
        </div>
        <div>
          <Select
            onChange={(e: any) => props.setFocusArea(e.target.value)}
            value={props.focusArea}
            label={
              <div className="space-request-questions-card-answer-text">
                Organization type *
              </div>
            }
          >
            <option selected={true} value="">
              {" "}
              --Select An Option--{" "}
            </option>
            {focusAreaData?.focusAreaList?.map((fa: any) => {
              return (
                <option key={fa} value={fa}>
                  {fa}
                </option>
              );
            })}
          </Select>
        </div>
        <div>
          <LDTextField
            value={props.website}
            onChange={(e: any) => handleWebsiteUrl(e)}
            label="Organization Website Link *"
            required={true}
            type="text"
          />
          {!isValidWebsite && (
            <span className="reg-form-error-container" style={{ color: "red" }}>
              Please Provide a Valid Link
            </span>
          )}
        </div>
      </CardItem>
      <Divider />
      <div className="info-card-buttons">
        <Button onClick={() => onBack(props.prevTab)}>{back}</Button>
        <Button
          disabled={!isDisabled(props.website, props.focusArea, isValidWebsite)}
          onClick={props.submit()}
          variant="primary"
        >
          {complete}
        </Button>
      </div>
    </Card>
  );
};

const isDisabled = (a: string, b: string, isValidLink: boolean) =>
  a && b && isValidLink;

const Account: React.SFC<CardProps> = (props: any) => {
  let match;
  const containsNumbersOnly = /^\d+$/.test(props?.phone);
  if (props?.phone?.length === 10) {
    match = props?.phone?.match(/^(\d{3})(\d{3})(\d{3,4})$/);
  }
  if (match) {
    props.setInputPhoneNum(
      "+1 (" + match[1] + ") " + match[2] + "-" + match[3]
    );
  }
  if (!containsNumbersOnly && props?.phone?.length <= 10) {
    props.setInputPhoneNum(props?.phone.replace(/\D/g, ""));
  }
  if (props?.phone?.length > 17) {
    props.setInputPhoneNum(props?.phone.substr(0, 17));
  }
  return (
    <Card>
      <CardItem>
        <div className="info-card-header">
          Welcome, {toCapitalize(props?.data?.primaryContactFname)}
        </div>
        <div className="info-card-text">
          You are an authorized user of a Spark Good Account. To edit your
          organization details, please sign into your
          <a
            href={SPARKGOOD_VERIFY_URL}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            DEED account
          </a>
          .
          <br />
        </div>
      </CardItem>
      <CardItem>
        <div className="info-card-input-div">
          <div className="info-card-input-button-div">
            <LDTextField
              label="First Name"
              disabled={true}
              type="text"
              value={toCapitalize(props?.data?.primaryContactFname)}
            />
          </div>
          <div className="info-card-input-button-div">
            <LDTextField
              label="Last Name"
              disabled={true}
              type="text"
              value={toCapitalize(props?.data?.primaryContactLname)}
            />
          </div>
        </div>
        <div>
          <LDTextField
            label="Email"
            disabled={true}
            type="email"
            value={props?.data?.primaryContactEmail}
          />
        </div>
        <div>
          <LDTextField
            label="Mobile Phone Number"
            value={props?.phone}
            onChange={(e: any) => props.setInputPhoneNum(e.target.value)}
            required={true}
            type="text"
          />
        </div>
      </CardItem>
      <div className="info-card-buttons">
        <div style={{ marginLeft: "2%", fontSize: "14px" }}>
          Above details are populated from the data provided during registration
          on
          <a
            href={SPARKGOOD_VERIFY_URL}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            DEED account
          </a>
          .
        </div>
        <Button
          disabled={
            !props.phone && (props.phone === "" || props.phone === null)
          }
          onClick={() => props.setActiveForm(props.nextTab)}
          variant="primary"
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};
