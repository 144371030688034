import React from 'react';
import withRouter from 'src/polyfils/customRouter';
import { WALMART_FAQ_URL } from '../../constants/constants';

class Faq extends React.Component {
    public componentDidMount(){
        window.open(WALMART_FAQ_URL);
        // @ts-ignore
        this.props.history.goBack();
    }
    public render(){
        return <div>downloading....</div>
    }
}

// @ts-ignore
export default withRouter(Faq);